import {createUseStyles} from 'react-jss'

import {
  borderSeparator
} from '~styles/mixins.styles'

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

export default createUseStyles({
  adminLoginFeaturesList: {
    ...borderSeparator('top')
  },

  subtitle: {
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: 18,
    color: COLORS.DARK_01,
    marginBottom: 20,
    [`@media (min-width: ${BREAKPOINTS.HERO_BIG}px)`]: {
      fontSize: 20
    }
  }
})