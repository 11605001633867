import React from 'react';

import useStyles from './admin-login-features-list.styles';

const AdminLoginFeaturesList = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.adminLoginFeaturesList}>
      <div className={classes.subtitle}>Here are some of our most popular features:</div>
      <p>Log in to your account to do the following and much more!</p>
      <ul>
        <li>
          Send Message Menu
          <ul>
            <li>SMS (top left) shows number of current text recipients</li>
            <li>Messages (top left) shows total messages sent since account created</li>
            <li>View More (top right) shows last 999 messages sent</li>
            <li>Export (top right) downloads all messages ever sent</li>
          </ul>
        </li>

        <li>
          Invite Users Menu
          <ul>
            <li>Invite new users via keyword, email, mobile opt-in</li>
          </ul>
        </li>

        <li>
          Groups Menu
          <ul>
            <li>Create Groups and have people join via keyword</li>
          </ul>
        </li>

        <li>
          Settings Menu
          <ul>
            <li>Sign up for the mobile portal</li>
            <li>Define your From Name - you can personalize your messages</li>
            <li>Setup your account keyword to let others join in seconds</li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default AdminLoginFeaturesList
