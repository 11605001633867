import React, { useState } from "react"
import { Link } from 'gatsby'

import Layout from "~components/layout/layout.component"
import AdminLoginForm from '~components/admin-login-form/admin-login-form.component';
import AdminLoginFeaturesList from '~components/admin-login-features-list/admin-login-features-list.component'
import Modal from '~components/modal/modal.component';
import SearchOrganizations from '~components/search-organizations/search-organizations.component';
import SEO from "~components/seo/seo.component"

import useStyles from '~components/admin-login/admin-login.styles';

const AdminLoginPage = props => {
  const classes = useStyles(props);

  const [organizationId, setOrganizationId] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);

  return (
    <Layout>
      <SEO title="Admin Log In" />
      <h1>Admin Log In</h1>
      <SearchOrganizations
        className={classes.searchOrganizations}
        onSelectedOrganizationChange={
          (organizationId, organizationName) => {
            setOrganizationId(organizationId)
            setOrganizationName(organizationName)
            }
        } 
      />
      Don't have an account? Get started — <Link to="/create-organization">Create Account</Link>
      <Modal
        title={organizationName}
        isShowing={organizationId}
        hide={() => setOrganizationId(null)}
      >
        <AdminLoginForm organizationHash={organizationId} />
      </Modal>

      <AdminLoginFeaturesList />

      <Link to="/">Go back to the homepage</Link>
    </Layout>
    )
  }
  
  export default AdminLoginPage
  